import React from 'react';
import GoogleTranslate from './GoogleTranslate';

const Footer = () => (
  <footer className='footer p-0'>
    <div className='row px-3 px-lg-5 py-3 py-lg-0'>
      <div className='col-12 col-md-4 py-0 py-lg-3 pe-3'>
        <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
          <p className='fw-bold'>Contact</p>
          <div className='mb-2' style={{ fontSize: '17px' }}>
            <div className='mb-1 text-white'>
              <a className='text-link-primary' title='contact' href='tel:+2072741240'>(207) 274-1240</a>
            </div>
            <div className='mb-1'>
              <a className='text-link-primary' href='mailto:evictionprevention@qualityhousingcoalition.org'>evictionprevention@qualityhousingcoalition.org</a>
            </div>
            <div className='mb-1'>
              <a className='text-link-primary' href='https://mainehousing.org/' target='_blank' rel='noopener noreferrer'>Mainehousing.org</a>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-4 py-0 py-lg-3'>
        <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
          <div className='d-flex justify-content-center'>
            <a href='https://www.mainehousing.org/education/fair-housing-education/fair-housing-mainehousing' target='_blank' rel='noopener noreferrer'>
              <img src='../images/eho.png' style={{ width: '100px' }} alt='equal housing logo' />
            </a>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-4 py-0 py-lg-3 pe-2'>
        <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
          <div className='mb-3'>
            <a title='privacy policy' className='fw-bold text-decoration-underline pointer' href={'https://www.mainehousing.org/privacy'} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
          </div>
          <p className='mb-2' style={{ fontSize: '17px' }}>
              Maine EPP strives to provide online resources in a safe, secure manner that respects your privacy when you visit our site.
          </p>
          <GoogleTranslate />
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
};

export default Footer;
