import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';
import { history } from '../../routers/AppRouter';

const initialState = {
  currentUser: {},
  isLoggedIn: false,
  loading: false,
  isPassedPrescreening: false,
  prescreenType: '',
  prescreenValues: {},
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authBegin: (auth) => {
      auth.loading = true;
    },
    authEnd: (auth) => {
      auth.loading = false;
    },
    login: (auth, action) => {
      auth.currentUser = action.payload;
      auth.isLoggedIn = true;
      auth.loading = false;
    },
    logout: (auth) => {
      auth.currentUser = {};
      auth.isLoggedIn = false;
      auth.loading = false;
    },
    savePrescreeningValues: (auth, action) => {
      auth.prescreenType = action.payload.userType;
      auth.prescreenValues = { ...auth.prescreenValues, ...action.payload.userValues };
      auth.loading = false;
    },
    resetPrescreeningValues: (auth) => {
      auth.prescreenType = '';
      auth.prescreenValues = {};
      auth.loading = false;
    },
    setPassPrescreening: (auth) => {
      auth.isPassedPrescreening = true;
      auth.loading = false;
    },
    resetPassPrescreening: (auth) => {
      auth.isPassedPrescreening = false;
      auth.loading = false;
    },
    signUp: (auth, action) => {
      auth.currentUser = action.payload;
      auth.isLoggedIn = true;
      auth.loading = false;
    },
    sendForgotPasswordEmail: (auth) => {
      auth.loading = false;
    },
    sendReportFraud: (auth) => {
      auth.loading = false;
    },
    resetPassword: (auth) => {
      auth.loading = false;
    },
    sendSubscriptionEmail: (auth) => {
      auth.loading = false;
    },
  },
});


// LOGIN
export const startLogin = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/login`, payload);
    const { token, ...user } = response.data;
    ls('user', user);
    dispatch(login(user));
    if (user.isLandlord) {
      history.push('/application/landlord');
    }
    if (user.isTenant) {
      history.push('/application/tenant');
    }
  } catch (error) {
    console.log(error.response);
    dispatch(authEnd());
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// LOGOUT
export const startLogout = () => async (dispatch, getState) => {
  try {
    await axios.get(`${baseURL}/logout`);
  } catch (error) {
    console.log(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  ls.clear();
  dispatch(logout());
  history.push('/');
};

// SIGN UP
export const startSignUp = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/register`, payload);
    const { token, ...user } = response.data;
    ls('user', user);
    await dispatch(signUp(user));
    payload.type === 'landlord' && history.push('/application/landlord');
    payload.type === 'tenant' && history.push('/application/tenant');
    ls.remove('inviteCode');
    dispatch(startResetPrescreeningValues());
  } catch (error) {
    console.log(error.response);
    dispatch(authEnd());
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// SAVE PRESCREENING VALUES
export const startSavePrescreeningValues = (userType, userValues) => (dispatch, getState) => {
  dispatch(authBegin());
  try {
    dispatch(savePrescreeningValues({ userType, userValues }));
    if (ls('prescreening')) {
      const savedValues = ls('prescreening');
      const updatedValues = { ...savedValues, ...userValues };
      ls('prescreening', updatedValues);
    } else {
      ls('prescreening', userValues);
    }
  } catch (error) {
    console.log(error.response);
    dispatch(authEnd());
  }
};

// RESET PRESCREENING VALUES
export const startResetPrescreeningValues = () => (dispatch, getState) => {
  dispatch(authBegin());
  try {
    if (ls('prescreening')) {
      ls.remove('prescreening');
    }
    dispatch(resetPrescreeningValues());
  } catch (error) {
    console.log(error.response);
    dispatch(authEnd());
  }
};

// SEND_FORGOT_PASSWORD_EMAIL
export const startSendForgotPasswordEmail = (payload, setSubmitting, cb) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/sendVerificationEmail`, payload);
    setSubmitting();
    dispatch(sendForgotPasswordEmail());
    cb();
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// RESET_PASSWORD
export const startResetPassword = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/resetPassword`, payload);
    dispatch(resetPassword());
    ls.clear();
    toast.success('Password updated. Please log back in.');
    history.push('/login');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// REPORT_FRAUD
export const startSendReportFraud = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/reportFraud`, payload);
    dispatch(sendReportFraud());
    toast.success('Thank you. Your fraud report has been submitted.');
    history.push('/');
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// ADD_SUBSCRIPTION_EMAIL
export const startSendSubscriptionEmail = (payload, setSubmitting) => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/ineligible`, payload);
    toast.success('Thank you for your interest in the Maine Rental Assistance Program. We will notify you of any program updates or changes.');
    setSubmitting();
    dispatch(sendSubscriptionEmail());
  } catch (error) {
    setSubmitting();
    console.log(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// RESET_PRESCREENING
export const startResetPrescreening = () => async (dispatch, getState) => {
  dispatch(authBegin());
  try {
    if (ls('prescreening')) {
      ls.remove('prescreening');
    }
    dispatch(resetPassPrescreening(true));
  } catch (error) {
    console.log(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

export const {
  authBegin,
  authEnd,
  sendForgotPasswordEmail,
  sendReportFraud,
  resetPassword,
  savePrescreeningValues,
  resetPrescreeningValues,
  setPassPrescreening,
  resetPassPrescreening,
  checkGeoLocation,
  checkAMI,
  signUp,
  sendSubscriptionEmail,
  login,
  logout,
} = slice.actions;

export default slice.reducer;
