import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { PublicRoute } from './index';
import { Loading } from '../../cdui';

const AllowApplicationRoute = ({ redirectPath = '/', children, isConfigLoading, isTenantSignUp = false }) => {
  const location = useLocation();

  const allowApplication = useSelector((state) => state.configs.allowApplications);
  const isPassedPrescreening = useSelector((state) => state.auth.isPassedPrescreening);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isLandlord = useSelector((state) => state.auth.currentUser.isLandlord);

  const type = isLandlord ? 'landlord' : 'tenant';

  // Check if the user is already logged in
  if (isLoggedIn) return <Navigate to={`/application/${type}`} replace from={{ from: location }} />;

  // Wait for fetching the configuration
  if (isConfigLoading) return <PublicRoute><Loading main={true} /></PublicRoute>;

  // Check if the application is allowed
  if (allowApplication === 'Yes') {
    // Check isPassedPrescreening on tenant sign up
    if (isTenantSignUp) {
      if (isPassedPrescreening) {
        return children;
      }
      return <Navigate to={redirectPath} replace from={{ from: location }} />;
    }
    return children;
  }
  return <Navigate to={redirectPath} replace from={{ from: location }} />;
};

AllowApplicationRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
  isConfigLoading: PropTypes.bool,
  isTenantSignUp: PropTypes.bool,
};

export default AllowApplicationRoute;
