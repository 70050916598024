import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { history } from '../routers/AppRouter';
import { useLocation } from 'react-router-dom';
import { Button } from '../cdui';


const NavBar = ({ isLoggedIn, onClickLogOut, onClickLogIn, isTranslatorOpened }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <nav className='navbar col-12 p-0 fixed-top d-flex flex-row bg-primary' style={{ marginTop: isTranslatorOpened ? '37px' : '' }}>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between'>
          <div className='pointer' onClick={() => history.push('/')}>
            <div className='mb-0 ms-3'>
              <img src='../images/logo-1.png' width={250} />
            </div>
          </div>
          <ul className='navbar-nav text-white'>
            {/* FAQ */}
            <li className={'nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center'} >
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                history.push('/faq');
              }}>
                <div>FAQ</div>
              </div>
            </li>

            {/* Required Documents */}
            <li className={'nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center'}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                history.push('/required-documents');
              }}>
                <div>Required Documents</div>
              </div>
            </li>

            {/* Resources */}
            {/* <li className={'nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center'}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                history.push('/resources');
              }}>
                <div>Resources</div>
              </div>
            </li> */}

            {/* Report Fraud */}
            <li className={'nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center'}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                history.push('/report-fraud');
              }}>
                <div>Report Fraud</div>
              </div>
            </li>

            {!isLoggedIn
              ? (
                <li className='nav-link px-3 d-none d-lg-flex'>
                  <Button variant='success' className='mx-1 mx-sm-3' type='button' style={{ minWidth: '80px' }} onClick={onClickLogIn}>Log in</Button>
                </li>
              ) : (
                <li className='nav-link px-3 d-none d-lg-flex'>
                  <Button variant='secondary' className='mx-1 mx-sm-3' type='button' style={{ minWidth: '80px' }} onClick={onClickLogOut}>Log out</Button>
                </li>
              )}

            {/* TABLET/MOBILE */}
            <li className={`nav-item dropdown ${activeDropdown ? 'show selected' : ''} d-flex d-lg-none justify-content-center align-items-center`}>
              <div className='nav-link mobile-menu pointer' onClick={() => setActiveDropdown(true)}>
                <span className='nav-icon fas fa-bars text-white' />
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeDropdown ? 'show' : ''}`}>

                <div className='dropdown-item' onClick={() => {
                  setActiveDropdown(false); history.push('/faq');
                }}>
                  <div className='body px-3'><span>FAQ</span></div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => {
                  setActiveDropdown(false); history.push('/required-documents');
                }}>
                  <div className='body px-3'><span>Required Documents</span></div>
                </div>

                <div className='dropdown-divider'></div>

                {/* <div className='dropdown-item' onClick={() => {
                  setActiveDropdown(false); history.push('/resources');
                }}>
                  <div className='body px-3'><span>Resources</span></div>
                </div>

                <div className='dropdown-divider'></div> */}

                <div className='dropdown-item' onClick={() => {
                  setActiveDropdown(false); history.push('/report-fraud');
                }}>
                  <div className='body px-3'><span>Report Fraud</span></div>
                </div>

                <div className='dropdown-divider'></div>

                {!isLoggedIn
                  ? (<div className='dropdown-item' onClick={() => {
                    setActiveDropdown(false); onClickLogIn();
                  }}>
                    <div className='body no-hover d-flex align-items-center px-3'>
                      <Button size='sm' className='btn btn-success btn-xs px-5 text-white' type='button'>Log in</Button>
                    </div>
                  </div>
                  ) : (<div className='dropdown-item' onClick={() => {
                    setActiveDropdown(false); onClickLogOut();
                  }}>
                    <div className='body no-hover d-flex align-items-center px-3'>
                      <Button size='sm' variant='secondary' className='px-5' type='button'>Log out</Button>
                    </div>
                  </div>
                  )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  onClickLogOut: PropTypes.func,
  onClickLogIn: PropTypes.func,
  isTranslatorOpened: PropTypes.bool,
};

export default NavBar;
